<template>
  <div>
    <van-nav-bar
      title="历史记录"
      left-arrow
      :border="false"
      fixed
      @click-left="$router.back()"
    />
    <div class="content">
      <div class="list-box">
        <div
          v-for="(checkItem, idx) of checkPointList"
          :key="idx"
          class="check-item-box"
        >
          <!-- <div class="title-box">
            <div>
              <span class="title">{{ `${checkItemIdx + 1}.${checkItem.name}` }}</span>
              <img
                class="tag-img"
                :src="statusTagSwitch(checkItem.status)"
                width="37"
                height="16"
              />
            </div>
          </div> -->
          <div class="form-box">
            <div class="date-box">
              <span>{{ checkItem.endTime || "--" }}</span>
              <div class="all-tag-box" style="padding-left: 8px">
                <i v-if="checkItem.verdict === 1" class="normal">正常</i>
                <i v-if="checkItem.verdict === 0" class="abnormal">异常</i>
                <i v-if="+checkItem.leakFlag === 1" class="loujian">漏检</i>
              </div>
            </div>
            <van-field
              class="custom-van-field"
              label-class="custom-label"
              :label-width="labelWidth"
              label="任务名称："
            >
              <template #input>
                <span>{{ checkItem.taskName || "--" }}</span>
              </template>
            </van-field>
            <van-field
              class="custom-van-field"
              label-class="custom-label"
              :label-width="labelWidth"
              label="任务开始时间："
            >
              <template #input>
                <span>{{ checkItem.validStartTime || "--" }}</span>
              </template>
            </van-field>
            <van-field
              class="custom-van-field"
              label-class="custom-label"
              :label-width="labelWidth"
              label="任务结束时间："
            >
              <template #input>
                <span>{{ checkItem.validEndTime || "--" }}</span>
              </template>
            </van-field>
            <van-field
              v-if="formModelTypeSwitch(2, checkItem.checkMethod)"
              class="custom-van-field"
              label-class="custom-label"
              :label-width="labelWidth"
              label="合格范围："
            >
              <template #input>
                <span>{{ checkItem.downBounds }}</span>
                <span>{{ checkItem.units }}</span>
                <span> - </span>
                <span>{{ checkItem.upBounds }}</span>
                <span>{{ checkItem.units }}</span>
              </template>
            </van-field>
            <van-field
              v-if="formModelTypeSwitch(1, checkItem.checkMethod)"
              class="custom-van-field"
              label-class="custom-label"
              :label-width="labelWidth"
              label="检查标准："
            >
              <template #input>
                <span>{{ checkItem.checkStandard }}</span>
              </template>
            </van-field>
            <van-field
              class="custom-van-field"
              label-class="custom-label"
              :label-width="labelWidth"
              label="检查方法："
            >
              <template #input>
                <span>{{ checkItem.checkMethodName }}</span>
              </template>
            </van-field>
            <template v-if="formModelTypeSwitch(2, checkItem.checkMethod)">
              <div class="view-row">
                <span class="label">测量值：</span>
                <p class="text">{{ checkItem.actualResult }}</p>
                <span v-if="checkItem.actualResult"
                  >（{{ getTaskCollectType(checkItem.collectType) }}）</span
                >
              </div>
            </template>
            <template v-if="formModelTypeSwitch(1, checkItem.checkMethod)">
              <div class="view-row">
                <span class="label">是否正常：</span>
                <p class="text">{{ checkItem.verdict === 0 ? "否" : "是" }}</p>
              </div>
              <div class="view-row">
                <span class="label">情况描述：</span>
                <p class="text">{{ checkItem.actualDesc }}</p>
              </div>
            </template>
            <div class="view-row">
              <span class="label">备注：</span>
              <p class="text">{{ checkItem.remark }}</p>
            </div>
            <template v-if="checkItem.files.length">
              <p class="view-row">现场照片：</p>
              <van-uploader
                v-model="checkItem.files"
                multiple
                :max-count="6"
                :max-size="1024 * 1024 * 10"
                accept="image/png,image/jpeg,image/jpg"
                lazy-load
                :deletable="false"
                :show-upload="false"
                upload-icon="plus"
                upload-text="上传照片"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTaskPointItemData } from "@/api/psm/inspectionNew";
import loujianTag from "../../assets/images/loujian-tag.png";
import yichangTag from "../../assets/images/yichang-tag.png";
import zhengchangTag from "../../assets/images/zhengchang-tag.png";
import { taskCollectTypeStr } from "@/utils";

export default {
  name: "TaskProjectHistory",
  components: {},
  data() {
    return {
      checkPointList: [],
      formModelWhiteList: {
        1: [10, 40],
        2: [20, 30]
      },
      labelWidth: "90px"
    };
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTaskPointItemData();
  },
  methods: {
    getTaskCollectType(v) {
      return taskCollectTypeStr(v);
    },
    async getTaskPointItemData() {
      const data = await getTaskPointItemData(this.$route.params.id);
      data.forEach(item => {
        if (!item.files) item.files = [];
        item.files.forEach(file => {
          file.url = this.getFileUrl(file.id, false);
          file.isImage = true;
        });
      });
      this.checkPointList = data;
    },
    getFileUrl(id, downloadFlag = true) {
      return `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=${downloadFlag}&fileName=${id}`;
    },
    statusTagSwitch(status) {
      const obj = {
        0: yichangTag,
        1: zhengchangTag,
        2: loujianTag
      };
      return obj[status];
    },
    formModelTypeSwitch(type, checkMethod) {
      return this.formModelWhiteList[type].includes(checkMethod);
    }
  }
};
</script>

<style lang="scss">
.list-box {
  .all-tag-box {
    display: inline-block;
    padding: 0 0 0 31px;
    vertical-align: 1px;
    span,
    i {
      display: inline-block;
      border-radius: 2px;
      font-size: 10px;
      line-height: 14px;
      padding: 2px 6px;
      margin: 0 8px 0 0;
    }
    .loujian {
      color: #e97617;
      background: rgba(233, 118, 23, 0.1);
    }
    .abnormal {
      color: #f13030;
      background: rgba(241, 48, 48, 0.1);
    }
    .normal {
      color: #fff;
      background: #7db227;
    }
  }

  .form-box {
    .custom-van-field {
      .custom-label {
        // width: auto;
        color: #8c8f97;
        margin: 0;
      }
      .van-field__control--custom {
        min-height: initial;
      }
    }
    .van-uploader {
      padding: 16px 0 0;
    }
    .van-uploader__wrapper:first-child {
      div:nth-child(3n + 3) {
        margin: 0 0 12px 0;
      }
    }
    .van-uploader__upload,
    .van-uploader__preview {
      width: 92px;
      height: 72px;
      box-sizing: border-box;
      overflow: hidden;
      background: #f6f7fa;
      border: 1px solid #e1e3e8;
      border-radius: 5px;
      margin: 0 16px 12px 0;
    }
    .van-uploader__upload-icon {
      color: #b8bcc6;
      // font-size: 24px;
    }
    .van-uploader__upload-text {
      margin-top: 7px;
      color: #b8bcc6;
      // font-size: 12px;
    }
    .van-uploader__preview-image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.content {
  padding: 78px 16px 20px;
  height: calc(100vh - 98px);
  background-color: rgba(236, 238, 242, 0.5);
  overflow: auto;
  .list-box {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 #dbdde4;
    .check-item-box:not(:last-child) {
      border-bottom: 1px dashed #e1e3e8;
    }
    // .check-item-box:not(:first-child) {
    //   padding: 23px 0 24px;
    // }
    .check-item-box {
      // padding: 0 0 24px;
      padding: 23px 0 24px;
      .form-box {
        padding: 0 16px 0;
        .date-box {
          display: flex;
          align-items: baseline;
          span {
            display: inline-block;
            font-size: 11px;
            font-weight: 400;
            color: #3b4664;
            background: #eaeff5;
            border-radius: 2px;
            padding: 0 6px;
            vertical-align: middle;
            margin: 0 8px 0 0;
            line-height: 16px;
            height: 16px;
          }
          img {
            vertical-align: middle;
          }
        }
        .custom-van-field {
          font-size: 12px;
          padding: 0;
          margin: 8px 0 0;
          // height: 17.01px;
          line-height: 18px;
        }
        .custom-van-field.input {
          line-height: 24px;
        }
        .custom-van-field::after {
          border-bottom: 0;
        }
        .custom-van-field::before {
          left: -8px;
          top: 2px;
        }
        .view-row {
          display: flex;
          font-size: 12px;
          padding: 0;
          margin: 8px 0 0;
          line-height: 18px;
          color: #8c8f97;
          .label {
            flex: none;
            width: 90px;
          }
          .text {
            word-break: break-all;
            color: #2e2e4d;
          }
        }
      }
    }
  }
}
</style>
